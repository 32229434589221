import React, { useState, useCallback, useRef, useMemo } from 'react';
import { FiPlus, FiMinus, FiCheckCircle, FiDownload } from 'react-icons/fi'
import { format } from 'date-fns';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import apiLiberacao from '../../services/apiLiberacao';

import ILanctosAgrupado from '../../services/ILanctosAgrupado';
import ILancamento from '../../services/ILancamento';
import IRetornoApi from '../../services/IRespostaApiLiberacao';

import {
  Container,
  Banner,
  ContainerFiltro,
  InputLocal,
  BotaoFiltro,
  BotaoFecharFolha,
  FormLancto,
  GroupBox,
  GroupBoxLegenda,
  InputCpf,
  InputDescricao,
  InputTipo,
  OpcaoInputTipo,
  InputValor,
  BotaoFormAdd,
  ContainerCartao,
  Cartao,
  LoadingContainer,
  Loading,
  ContainerHolerith,
  CabecalhoHolerith,
  VerbaHolerith,
  DescricaoVerbaHolerith,
  TotalVerbaHolerith,
  RodapeHolerith,
  DescricaoRodapeHolerith,
  TotalRodapeHolerith,
  BotaoExcluirVerba,
} from './styles';

import bannerImg from '../../assets/banner-home.png';

interface IDadosFecharFolha {
  cnpj: string;
  data: string;
}

const Home: React.FC = () => {
  const { usuario, senha } = useAuth();
  const { addToast } = useToast();

  const [lanctosAgrupados, setLanctosAgrupados] = useState<ILanctosAgrupado[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [cnpjSelecionado, setCnpjSelecionado] = useState('');
  const [vencimentoSelecionado, setVencimentoSelecionado] = useState('');

  const [efetuouBusca, setEfetuouBusca] = useState(false);
  const [exibirInclusao, setExibirInclusao] = useState(false);

  const [cpfFuncionario, setCpfFuncionario] = useState('');
  const [cpfFuncionarioErro, setCpfFuncionarioErro] = useState('');
  const [descricao, setDescricao] = useState('');
  const [descricaoErro, setDescricaoErro] = useState('');
  const [tipoSelecionado, setTipoSelecionado] = useState('+');
  const [valor, setValor] = useState(0.00);
  const [valorErro, setValorErro] = useState('');

  const [cpfSelecionado, setCpfSelecionado] = useState('');

  const [lancamentosHolerith, setLancamentosHolerith] = useState<ILancamento[]>([]);

  const inputVenctoRef = useRef<HTMLInputElement>(null);
  const inputCpfFuncRef = useRef<HTMLInputElement>(null);
  const inputDescricaoRef = useRef<HTMLInputElement>(null);

  const buscar = useCallback(() => {
    let token: string = '';
    setLanctosAgrupados([]);
    setLoading(true);
    setEfetuouBusca(false);
    setExibirInclusao(false);
    setCpfSelecionado('');

    // console.log(inputCnpjRef.current?.value);
    // console.log(inputVenctoRef.current?.value);

    if (cnpjSelecionado === '') {
      addToast({
        type: 'error',
        title: 'Cnpj não foi informado!',
      });
      setLoading(false);
      return;
    // } else if (!inputVenctoRef.current?.value) {
    } else if (!vencimentoSelecionado) {
      addToast({
        type: 'error',
        title: 'Vencimento não foi informado!',
      });
      setLoading(false);
      return;
    }

    // const vencto = inputVenctoRef.current.value;

    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;
      apiLiberacao.get('/participacao/lancamento/buscar-cnpj-data', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: {
          cnpj: cnpjSelecionado,
          data: vencimentoSelecionado,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          setLanctosAgrupados(resp.data.dados);
          setEfetuouBusca(true);
          setExibirInclusao(true);

          if (inputCpfFuncRef.current) {
            inputCpfFuncRef.current.focus();
          }
        } else {
          addToast({
            type: 'error',
            title: 'Falha interna ao buscar lançamentos',
          });
        }
        setLoading(false);
      });
    }).catch((error) => {
      setLoading(false);
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario, cnpjSelecionado, vencimentoSelecionado, setEfetuouBusca])

  const fecharFolha = useCallback(() => {
    let token: string = '';

    if (cnpjSelecionado === '') {
      addToast({
        type: 'error',
        title: 'Cnpj não foi informado!',
      });
      return;
    // } else if (!inputVenctoRef.current?.value) {
    } else if (!vencimentoSelecionado) {
      addToast({
        type: 'error',
        title: 'Vencimento não foi informado!',
      });
      return;
    }

    setLoading(true);

    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;
      apiLiberacao.post('/participacao/lancamento/fechar-folha',
      {
        cnpj: cnpjSelecionado,
        data: vencimentoSelecionado,
      } as IDadosFecharFolha,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }).then((resp) => {
        if (resp.data.sucesso) {
          addToast({
            type: 'success',
            title: 'Folha fechada com sucesso!',
          });

          buscar();
        } else {
          addToast({
            type: 'error',
            title: 'Falha interna ao fechar folha',
            description: 'Não foi possível fechar a folha do CNPJ e vencimento selecionado',
          });
        }
        setLoading(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha interna ao fechar folha',
          description: 'Não foi possível fechar a folha do CNPJ e vencimento selecionado',
        });
      });
    }).catch((error) => {
      setLoading(false);
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario, cnpjSelecionado, vencimentoSelecionado, buscar])

  const aoAlterarTipo = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoSelecionado(event.target.value);
  }, [setTipoSelecionado]);

  const aoAlterarCnpj = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCnpjSelecionado(event.target.value);
    setLanctosAgrupados([]);
    setEfetuouBusca(false);
    setExibirInclusao(false);
    setCpfSelecionado('');
  }, [setCnpjSelecionado]);

  const aoSairCnpj = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    let cnpjTemp: string = event.target.value;
    // Somente números
    cnpjTemp = cnpjTemp.replace(/[^0-9]/g, '');

    if (cnpjTemp.length === 14) {
      cnpjTemp = `${cnpjTemp.substr(0, 2)}.${cnpjTemp.substr(2, 3)}.${cnpjTemp.substr(5, 3)}/${cnpjTemp.substr(8, 4)}-${cnpjTemp.substr(12, 2)}`;
      setCnpjSelecionado(cnpjTemp);

      // Sugere dia de vencto com base no histórico
      apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
        const token = response.data.access_token;
        apiLiberacao.get<IRetornoApi<number>>('/participacao/lancamento/buscar-dia-vencimento', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            cnpj: cnpjSelecionado,
          }
        }).then((resp) => {
          if (resp.data.sucesso) {
            if (resp.data.dados > 0) {
              const dataAtual = new Date();
              const venctoSugerir = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), resp.data.dados);
              setVencimentoSelecionado(format(venctoSugerir, "yyyy-MM-dd"));
            }
          } else {
            addToast({
              type: 'error',
              title: 'Falha interna ao buscar lançamentos',
            });
          }
          setLoading(false);
        });
      }).catch((error) => {
        setLoading(false);
        addToast({
          type: 'info',
          title: 'Erro na autenticação',
          description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
        });
      })
    }
  }, [setCnpjSelecionado,addToast,cnpjSelecionado,senha,usuario]);

  const aoAlterarVencimento = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setVencimentoSelecionado(event.target.value);
    setLanctosAgrupados([]);
    setEfetuouBusca(false);
    setExibirInclusao(false);
    setCpfSelecionado('');
  }, [setVencimentoSelecionado]);

  const aoAlterarCpfFuncionario = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCpfFuncionario(event.target.value);
    setCpfFuncionarioErro('');
  }, [setCpfFuncionario,setCpfFuncionarioErro]);

  const aoSairCpf = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    let cpfTemp: string = event.target.value;
    // Somente números
    cpfTemp = cpfTemp.replace(/[^0-9]/g, '');

    if (cpfTemp.length === 11) {
      cpfTemp = `${cpfTemp.substr(0, 3)}.${cpfTemp.substr(3, 3)}.${cpfTemp.substr(6, 3)}-${cpfTemp.substr(9, 2)}`;
      setCpfFuncionario(cpfTemp);
    }
  }, [setCpfFuncionario]);

  const aoAlterarDescricao = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescricao(event.target.value);
    setDescricaoErro('');
  }, [setDescricao,setDescricaoErro]);

  const aoAlterarValor = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValor(Number(event.target.value));
    setValorErro('');
  }, [setValor,setValorErro]);

  const aoClicarCartao = useCallback((cpfParametro: string) => {
    setCpfSelecionado('');

    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      const token = response.data.access_token;
      apiLiberacao.get('/participacao/lancamento/buscar-cnpj-cfp-data', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: {
          cnpj: cnpjSelecionado,
          cpf: cpfParametro,
          data: vencimentoSelecionado,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          // console.log(resp.data.dados);
          setLancamentosHolerith(resp.data.dados);
          setCpfSelecionado(cpfParametro);
        } else {
          addToast({
            type: 'error',
            title: 'Falha interna ao buscar lançamentos',
          });
        }
      });
    }).catch((error) => {
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, cnpjSelecionado,senha,usuario,vencimentoSelecionado]);

  const incluirLancamento = useCallback(() => {
    try {
      if (!efetuouBusca) {
        addToast({
          type: 'info',
          title: 'Buscar antes por CNPJ e Vencimento',
        });
        return;
      }

      setCpfFuncionarioErro('');
      setDescricaoErro('');
      setValorErro('');

      let erroValidacao = false;
      if (cpfFuncionario === '') {
        setCpfFuncionarioErro('Campo obrigatório!');
        erroValidacao = true;
      }
      if (descricao === '') {
        setDescricaoErro('Campo obrigatório!');
        erroValidacao = true;
      }
      if (Number(valor) === 0) {
        setValorErro('Campo obrigatório!');
        erroValidacao = true;
      }

      if (erroValidacao) {
        addToast({
          type: 'info',
          title: 'Pendências na validação',
          description: 'Foram encontradas pendências na validação dos dados, efetue as correções indicadas e tente novamente.',
        });
        return;
      }

      const lancto: ILancamento = {
        cnpjEmpresa: cnpjSelecionado,
        vencimento: vencimentoSelecionado,
        cpfFuncionario: cpfFuncionario,
        descricao: descricao,
        tipo: tipoSelecionado,
        valor: valor,
      };

      apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
        const token = response.data.access_token;
        apiLiberacao.post<IRetornoApi<any>>('/participacao/lancamento/incluir', lancto, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }).then((resp) => {
          if (resp.data.sucesso) {
            addToast({
              type: 'success',
              title: 'Lançamneto incluído com sucesso',
            });

            buscar();

            setTimeout(() => {
              if (inputDescricaoRef.current) {
                inputDescricaoRef.current.focus();
              }
              aoClicarCartao(cpfFuncionario);
            }, 1000);

            setDescricao('');
            setTipoSelecionado('+');
            setValor(0);
          } else {
            if (resp.data.mensagens.length > 0) {
              addToast({
                type: 'info',
                title: 'Verifique os dados inseridos!',
                description: resp.data.mensagens[0].texto,
              });
            } else {
              addToast({
                type: 'error',
                title: 'Erro na inclusão de lançamento',
                description: 'Ocorreu algum erro na inclusão do lançamento, tente novamente.',
              });
            }
          }
        });
      }).catch((error) => {
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
        });
      })

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na inclusão',
        description: 'Ocorreu um erro ao tentar incluir lançamento, tente novamente.',
      });
    }
  }, [addToast,setCpfFuncionarioErro,cpfFuncionario,setDescricaoErro,setValorErro,descricao,valor,cnpjSelecionado,tipoSelecionado,vencimentoSelecionado,senha,usuario,buscar,efetuouBusca,aoClicarCartao]);

  const excluirLancamento = useCallback((id: number | undefined, cpfFuncPar: string) => {
    if (id === undefined) {
      return;
    }
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      const token = response.data.access_token;
      apiLiberacao.post<IRetornoApi<any>>(`/participacao/lancamento/excluir/${id.toString()}`, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          // console.log(resp.data.dados);
          setLancamentosHolerith([]);
          setCpfSelecionado('');
          addToast({
            type: 'success',
            title: 'Lançamento excluído com sucesso',
          });
          buscar();

          setTimeout(() => {
            aoClicarCartao(cpfFuncPar);
          }, 1000);
        } else {
          if (resp.data.mensagens.length > 0) {
            addToast({
              type: 'info',
              title: 'Exclusão não permitida!',
              description: resp.data.mensagens[0].texto,
            });
          } else {
            addToast({
              type: 'error',
              title: 'Falha interna ao excluir lançamento',
            });
          }
        }
      });
    }).catch((error) => {
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast,buscar,senha,usuario,aoClicarCartao]);

  const iconeStatus = useCallback((item: ILancamento) => {
    switch (item.status) {
      case "FECHADO": return <FiCheckCircle title="Verba já foi fechada!" />;
      case "BAIXADO": return <FiDownload title="Verba já foi baixada pelo cliente!" />;
      default: return <p></p>;
    }
  }, []);

  const totalHolerith = useMemo(() => {
    if (lancamentosHolerith === null || lancamentosHolerith === undefined) {
      return 0;
    }
    if (lancamentosHolerith.length === 0) {
      return 0;
    }
    const totalPos: number = lancamentosHolerith.filter(lancto => lancto.tipo === '+').reduce((sum, current) => sum + current.valor, 0);
    const totalNeg: number = lancamentosHolerith.filter(lancto => lancto.tipo === '-').reduce((sum, current) => sum + current.valor, 0);
    return (totalPos - totalNeg);
  }, [lancamentosHolerith]);

  return (
    <>
      <Container>
        <Banner src={bannerImg} alt="Banner Sizex" />

        <ContainerFiltro>
          <InputLocal
            variant="outlined"
            margin="normal"
            // required
            // fullWidth
            id="cnpjEmpresa"
            label="CNPJ Empresa"
            name="cnpjEmpresa"
            // autoComplete="email"
            autoFocus
            value={cnpjSelecionado}
            onChange={aoAlterarCnpj}
            onBlur={aoSairCnpj}
          />

          <InputLocal
            variant="outlined"
            margin="normal"
            // required
            // fullWidth
            id="vencimento"
            label="Vencimento"
            name="vencimento"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={inputVenctoRef}
            value={vencimentoSelecionado}
            onChange={aoAlterarVencimento}
          />

          <BotaoFiltro
            type="button"
            variant="contained"
            onClick={buscar}
          >Buscar</BotaoFiltro>
          <BotaoFecharFolha
            type="button"
            variant="contained"
            onClick={fecharFolha}
          >Fechar Folha</BotaoFecharFolha>
        </ContainerFiltro>

        { exibirInclusao && (
        <FormLancto>
          <GroupBox>
            <GroupBoxLegenda>Inclusão de Lançamento</GroupBoxLegenda>

            <InputCpf
              variant="outlined"
              margin="normal"
              // required
              // fullWidth
              id="cpfFuncionario"
              label="CPF Funcionário"
              name="cpfFuncionario"
              // autoComplete="email"
              inputRef={inputCpfFuncRef}
              value={cpfFuncionario}
              onChange={aoAlterarCpfFuncionario}
              onBlur={aoSairCpf}
              error={cpfFuncionarioErro !== ''}
              helperText={cpfFuncionarioErro}
            />
            <InputDescricao
              variant="outlined"
              margin="normal"
              // required
              // fullWidth
              id="descricao"
              label="Descrição"
              name="descricao"
              // autoComplete="email"
              inputRef={inputDescricaoRef}
              value={descricao}
              onChange={aoAlterarDescricao}
              error={descricaoErro !== ''}
              helperText={descricaoErro}
            />
            <InputTipo
              select
              variant="outlined"
              margin="normal"
              // required
              // fullWidth
              id="tipo"
              label="Tipo"
              name="tipo"
              value={tipoSelecionado}
              onChange={aoAlterarTipo}
              // autoComplete="email"
              // inputRef={inputCnpjRef}
            >
              {['+','-'].map((option) => (
                <OpcaoInputTipo key={option} value={option}>
                  {option}
                </OpcaoInputTipo>
              ))}
            </InputTipo>
            <InputValor
              variant="outlined"
              margin="normal"
              type="number"
              // required
              // fullWidth
              id="valor"
              label="Valor"
              name="valor"
              // autoComplete="email"
              // inputRef={inputCnpjRef}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: { textAlign: 'right' }
              }}
              value={valor}
              onChange={aoAlterarValor}
              error={valorErro !== ''}
              helperText={valorErro}
            />
            <BotaoFormAdd type="button" variant="contained" onClick={incluirLancamento}>
              <FiPlus />
            </BotaoFormAdd>
          </GroupBox>
        </FormLancto>
        )}


        {loading && (
          <LoadingContainer>
            <Loading size={60} />
          </LoadingContainer>
        )}

        <ContainerCartao>
          {lanctosAgrupados.map(lancto => (
            <Cartao key={lancto.cpf} onClick={() => aoClicarCartao(lancto.cpf)}>
              <h3>CPF: {lancto.cpf}</h3>
              <p>Total Proventos: R$ {lancto.totalProventos.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
              <p>Total Descontos: R$ {lancto.totalDescontos.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
              <p>Saldo: R$ {(lancto.totalProventos - lancto.totalDescontos).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
            </Cartao>
          ))}
        </ContainerCartao>

        {cpfSelecionado !== '' && (
          <ContainerHolerith>
            <CabecalhoHolerith>
              <h2>Holerith - {cpfSelecionado}</h2>
            </CabecalhoHolerith>

            {lancamentosHolerith.map(lanctoHol => (
              <VerbaHolerith key={lanctoHol.id}>
                <BotaoExcluirVerba title="Excluir Lançamento" onClick={() => excluirLancamento(lanctoHol.id, lanctoHol.cpfFuncionario)}><FiMinus/></BotaoExcluirVerba>
                <DescricaoVerbaHolerith>
                  <p>{lanctoHol.descricao} ({lanctoHol.tipo})</p>
                  {iconeStatus(lanctoHol)}
                </DescricaoVerbaHolerith>

                <TotalVerbaHolerith>
                  <p>{lanctoHol.tipo} R$ {lanctoHol.valor.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
                </TotalVerbaHolerith>
              </VerbaHolerith>
            ))}

            <RodapeHolerith>
              <DescricaoRodapeHolerith>
                <p>TOTAL LÍQUIDO</p>
              </DescricaoRodapeHolerith>
              <TotalRodapeHolerith>
                <p>R$ {totalHolerith.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
              </TotalRodapeHolerith>
            </RodapeHolerith>
          </ContainerHolerith>
        )}

      </Container>
    </>
  );
}

export default Home;
