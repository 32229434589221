import styled, { keyframes } from 'styled-components';

import {
  TextField,
  Button,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  animation: ${appearFromLeft} 1s;

  /* 100% da parte visível da tela - total da altura */
  /* height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1;

  min-height: 90vh;
  position: relative;

  /* padding: 20px 50px 30px 50px; */

  padding-bottom: 30px;
`;

export const Banner = styled.img`
  width: 100%;
`;

export const ContainerFiltro = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 20px;
`;

export const BotaoFiltro = styled(Button)`
  background-color: var(--cor-login-botao) !important;
  color: var(--cor-login-fundo) !important;
  &:hover {
    background-color: var(--cor-login-botao-hover) !important;
  }

  width: 145px !important;
  height: 50px;

  margin-left: 20px !important;
  margin-top: 5px !important;
`;

export const BotaoFecharFolha = styled(Button)`
  background-color: #bcbf15 !important;
  color: var(--cor-login-fundo) !important;
  &:hover {
    background-color: var(--cor-login-botao-hover) !important;
  }

  width: 145px !important;
  height: 50px;

  margin-left: 20px !important;
  margin-top: 5px !important;
`;

export const InputLocal = styled(TextField)`
  :nth-child(2) {
    margin-left: 20px;
  }
`;

export const FormLancto = styled.form``;

export const GroupBox = styled.fieldset`
  border: 1px solid var(--cor-login-contorno-input) !important;
  border-radius: 10px;
  padding: 0 20px 15px !important;
  margin-top: 25px;
`;

export const GroupBoxLegenda = styled.legend`
  font-size: 20px !important;
  /* font-weight: bold !important; */
  text-align: left !important;
  color: var(--cor-login-contorno-input);
  width: auto;
  padding: 0px 5px;
  border-bottom: none;
`;

export const InputCpf = styled(TextField)`
  width: 170px;
`;

export const InputDescricao = styled(TextField)`
  width: 400px;
  margin-left: 15px !important;
`;

export const InputTipo = styled(TextField)`
  width: 80px;
  margin-left: 15px !important;
`;

export const OpcaoInputTipo = styled(MenuItem)``;

export const InputValor = styled(TextField)`
  width: 150px;
  margin-left: 15px !important;
`;

export const BotaoFormAdd = styled(Button)`
  background-color: var(--cor-login-botao) !important;
  color: var(--cor-login-fundo) !important;
  &:hover {
    background-color: var(--cor-login-botao-hover) !important;
  }

  width: 30px !important;
  height: 50px !important;

  margin-left: 15px !important;
  margin-top: 18px !important;

  svg {
    font-size: 30px;
  }
`;

export const ContainerCartao = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 20px;
`;

export const Cartao = styled.div`
  animation: ${appearFromLeft} 0.3s;

  cursor: pointer;

  margin-top: 20px;
  :not(:last-child) {
    margin-right: 20px;
  }
  width: 300px;

  -webkit-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  -moz-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);

  border-radius: 15px;
  border: 2px solid whitesmoke;

  padding: 15px;

  display: flex;
  flex-direction: column;

  transition: all ease 0.3s;

  &:hover {
    background-color: #f2f2f2;
  }
  z-index: 1;

  h3 {
    color: var(--cor-login-contorno-input);
    flex: 1;
    margin-right: 10px;
    text-align: center;
    /* margin-top: 0px; */
  }

  p {
    color: var(--cor-fundo);
    margin-top: 4px;

    text-align: center;

    /* a partir do segundo p */
    /* & + p {
      margin-top: 4px;
    } */
  }
`;

export const LoadingContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Loading = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: var(--cor-login-contorno-input);
  }
`;

export const ContainerHolerith = styled.div`
  animation: ${appearFromLeft} 0.3s;

  margin-top: 40px;
  width: 700px;
  height: auto;

  -webkit-box-shadow: 3px 5px 12px -1px var(--cor-login-contorno-input);
  -moz-box-shadow: 3px 5px 12px -1px var(--cor-login-contorno-input);
  box-shadow: 3px 5px 12px -1px var(--cor-login-contorno-input);

  border-radius: 5px;
  border: 2px solid var(--cor-login-contorno-input);

  /* padding: 15px; */

  display: flex;
  flex-direction: column;

  transition: all ease 0.3s;

  z-index: 1;
`;

export const CabecalhoHolerith = styled.div`
  height: auto;
  width: auto;
  border-bottom: 2px solid var(--cor-login-contorno-input);

  padding: 15px;

  &:hover {
    background-color: #f2f2f2;
  }

  h2 {
    text-align: center;
    color: var(--cor-fundo);
    font-weight: bold;
  }
`;

export const VerbaHolerith = styled.div`
  height: auto;
  width: auto;
  /* border-bottom: 1px solid var(--cor-login-contorno-input); */

  &:hover {
    background-color: #f2f2f2;
  }

  display: flex;
`;

export const DescricaoVerbaHolerith = styled.div`
  /* pra alinhar a esquerda e manter o texto alinhado a esquerda só com o flex 1 */
  /* margin: 0 auto; */
  display: flex;
  flex: 1;
  padding: 10px;

  p {
    text-align: left;
    color: var(--cor-fundo);
    font-weight: bold;
  }

  svg {
    color: var(--cor-login-contorno-input);
    font-size: 20px;
    margin-left: 10px;
  }
`;

export const TotalVerbaHolerith = styled.div`
  width: 150px;
  padding: 10px;
  border-left: 2px solid var(--cor-login-contorno-input);

  p {
    text-align: right;
    color: var(--cor-fundo);
    font-weight: bold;
  }
`;

export const RodapeHolerith = styled.div`
  height: auto;
  width: auto;
  border-top: 2px solid var(--cor-login-contorno-input);

  display: flex;

  &:hover {
    background-color: #f2f2f2;
    color: var(--cor-fundo);
  }
`;

export const DescricaoRodapeHolerith = styled.div`
  /* pra alinhar a esquerda e manter o texto alinhado a esquerda só com o flex 1 */
  /* margin: 0 auto; */
  flex: 1;
  padding: 10px;

  p {
    text-align: right;
    color: var(--cor-fundo);
    font-size: 18px;
    font-weight: bold;
  }
`;

export const TotalRodapeHolerith = styled.div`
  width: 150px;
  padding: 10px;
  border-left: 2px solid var(--cor-login-contorno-input);

  p {
    text-align: right;
    color: var(--cor-fundo);
    font-size: 18px;
    font-weight: bold;
  }
`;

export const BotaoExcluirVerba = styled.button`
  margin-top: 11px;
  margin-left: 10px;

  width: 16px;
  height: 16px;
  border-radius: 50%;

  border: 0;

  background-color: var(--cor-login-contorno-input);
  color: white;

  transition: all ease 0.3s;

  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);

  &:hover {
    background: var(--cor-login-contorno-input-hover);
  }

  svg {
    font-size: 14px;
  }
`;
