import axios from 'axios';

const apiLiberacao = axios.create({
  // Local:
  // baseURL: 'http://localhost:52619/',
  // GoDaddy:
  // baseURL: 'http://50.63.13.60:80/liberacao/',
  // SmartAspNet:
  baseURL: 'http://sizexserver-001-site4.htempurl.com/',
});

export default apiLiberacao;
