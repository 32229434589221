import styled, { css, keyframes } from 'styled-components';

interface IContainerOverlayProps {
 isShow: boolean;
}

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  animation: ${appearFromTop} 1s;
`;

export const ContainerOverlay = styled.div<IContainerOverlayProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  transition: all ease 0.5s;

  ${props => props.isShow && css`
    opacity: 0.5;
    visibility: visible;
  `}

  ${props => !props.isShow && css`
    opacity: 0;
    visibility: hidden;
  `}

  z-index: 45 !important;
`;
