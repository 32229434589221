import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';

import { Container, TopWrapper, MenuWrapper } from './styles';

import { LeftSideBarContext } from '../index';

import BurgerButton from '../BurgerButton';

const LeftSection: React.FC = () => {
  const { isShowSideBar, setIsShowSideBar } = useContext(LeftSideBarContext);

  return (
    <Container isShow={isShowSideBar}>
      <TopWrapper>
        <BurgerButton onClick={() => setIsShowSideBar(false)} />
      </TopWrapper>
      <MenuWrapper>
        <li><Link to="/home" onClick={() => setIsShowSideBar(false)}><FiHome/>Home</Link></li>
        {/* <li><Link to="/cobranca" onClick={() => setIsShowSideBar(false)}><FiDollarSign/>Cobrança</Link></li> */}
      </MenuWrapper>
    </Container>
  );
}

export default LeftSection;
