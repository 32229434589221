import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 4em;
  background-color: var(--cor-fundo);
  -webkit-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);

  display: flex;
  align-items: center;

  z-index: 50 !important;

  p {
    flex: 1;
  }

  svg {
    font-size: 40px;
    color: var(--cor-icones-barra);
    margin-right: 16px;
    cursor: pointer;

    z-index: 40 !important;

    transition: color 0.3s;

    &:hover {
      color: var(--cor-icones-barra-hover);
    }

    &:active {
      color: var(--cor-fundo);
    }
  }
`;
