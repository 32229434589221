import React, { createContext, useCallback, useState, useContext } from 'react';
import { Base64 } from 'js-base64';

import apiLiberacao from '../services/apiLiberacao';
import { useToast } from './ToastContext';

interface AuthState {
  authNome: string;
  authSenha: string;
}

interface SignInCredentials {
  nome: string;
  senha: string;
}

interface AuthContextData {
  usuario: string;
  senha: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const [data, setData] = useState<AuthState>(() => {
    const authNome = localStorage.getItem('@AppSizex:secret5');
    const authSenha = localStorage.getItem('@AppSizex:secret2');
    if (authNome && authSenha) {
      return {
        authNome: Base64.decode(authNome),
        authSenha: Base64.decode(authSenha),
      }
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ nome, senha }) => {
    // console.log('sign in entrada');
    try {
      const body: string = `userName=${nome}&password=${senha}&grant_type=password`;
      // console.log('body', body);

      apiLiberacao.post('/sizex/api/obtertoken', body).then((response) => {
        // console.log('resposta token', response);
        localStorage.setItem('@AppSizex:secret5', Base64.encode(nome));
        localStorage.setItem('@AppSizex:secret2', Base64.encode(senha));
        localStorage.setItem('@AppSizex:secret3', Base64.encode('vitor'));
        localStorage.setItem('@AppSizex:secret4', Base64.encode('vma2534'));

        setData({
          authNome: nome,
          authSenha: senha,
          });
      }).catch((error) => {
        addToast({
          type: 'info',
          title: 'Erro na autenticação',
          description: 'Verifique se o usuário e senha foram digitados corretamente.',
        });
      })
    } catch (error) {
      console.log(error);
      addToast({
        type: "error",
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro interno no sistema.',
      });
    }
  }, [addToast]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@AppSizex:secret5');
    localStorage.removeItem('@AppSizex:secret2');
    localStorage.removeItem('@AppSizex:secret3');
    localStorage.removeItem('@AppSizex:secret4');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ usuario: data.authNome, senha: data.authSenha, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
