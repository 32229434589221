import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    body {
        /* background: #312E38; */
        /* cor padrão do texto */
        /* color: #FFF; */
        --webkit-font-smoothing: antialiased;
    }

    body, input, button {
      font-family: Roboto, Arial, Helvetica, sans-serif;
        /* font-size: 16px; */
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }

    /* #root {
        max-width: 960px;
        margin: 0 auto;
        padding: 40px 20px;
    } */

    button {
        cursor: pointer;
    }



    :root {
      /* ======================================================== */
      /* azul da hora */
      /* --cor-fundo: #668cff;
      --cor-fonte: #f2f2f2;
      --cor-fonte-hover: #dbdbdb;
      --cor-icones-barra: #f2f2f2;
      --cor-icones-barra-hover: #dbdbdb; */
      /* ======================================================== */



      /* ======================================================== */
      /* sizex dark */
      /* --cor-fundo: #130d06;
      --cor-fonte: #f2f2f2;
      --cor-fonte-hover: #9cc520;
      --cor-icones-barra: #9cc520;
      --cor-icones-barra-hover: #baf40b; */
      /* sizex */
      --cor-fundo: #537b7d;
      --cor-fonte: #f2f2f2;
      --cor-fonte-hover: #9cc520;
      --cor-icones-barra: #fff;
      --cor-icones-barra-hover: #baf40b;
      /* login dark */
      /* --cor-login-fundo: #130d06;
      --cor-login-principal: #9cc520;
      --cor-login-contorno-input-hover: #f2f2f2;
      --cor-login-fonte: #fff;
      --cor-login-placeholder-input: gray; */
      /* login light */
      --cor-login-fundo: #fff;
      --cor-login-contorno-input: #c4cc45;
      --cor-login-contorno-input-hover: #bee250;
      --cor-login-fonte: #3d5a5c;
      --cor-login-placeholder-input: gray;
      --cor-login-botao: #537b7d;
      --cor-login-botao-hover: #c4cc45;

      /* tooltip */
      --cor-tooltip-erro: #e60000;
      --cor-tooltip-sucesso: #2eb82e;
      --cor-tooltip-info: #537b7d;

      /* ======================================================== */
    }

    /* Remover cor do autocomplete do chrome */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      /* border: 1px solid green; */
      -webkit-text-fill-color: var(--cor-login-botao);
      box-shadow: 0 0 0px 1000px transparent inset;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }




    /* Estilos padrão dos inputs do material-ui */
    .MuiTextField-root {
      /* contorno do input */
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: var(--cor-login-contorno-input) !important;
          }
        }
      }
      /* Contorno do input no hover */
      &:hover {
        & .MuiOutlinedInput-root {
          fieldset {
            &.MuiOutlinedInput-notchedOutline {
              border-color: var(--cor-login-contorno-input-hover) !important;
            }
          }
        }
      }
      /* contorno quando foca no input */
      & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
          border-color: var(--cor-login-contorno-input) !important;
        }
      }
      /* cor da fonte do input */
      & .MuiOutlinedInput-input {
        color: var(--cor-login-fonte) !important;
        /* letra maiúscula no input */
        text-transform: uppercase;
      }
      /* label quando preenchido */
      & label.MuiFormLabel-filled {
        color: var(--cor-login-contorno-input) !important;
      }
      /* label em cima do input */
      & label.Mui-focused {
        color: var(--cor-login-fonte) !important;
      }
      /* seria o placeholder */
      > label {
        color: var(--cor-login-placeholder-input);
      }
    }



    /* Estilo padrão do button do Material-UI */
    /* REMOVI DAQUI PQ DEPOIS NAO CONSEGUIA MAIS REESCREVER LOCALMENTE */
    /* .MuiButton-root {
      background-color: var(--cor-login-botao) !important;
      color: var(--cor-login-fundo) !important;

      &:hover {
        background-color: var(--cor-login-botao-hover) !important;
      }
    } */


`;
