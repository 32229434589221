import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 1em;

  /* flex: 1; */
  z-index: 1;

  i {
    background-color: var(--cor-icones-barra);
    width: 2em;
    height: 0.25em;
    margin: 0.15em;
    border-radius: 0.125em;
  }
`;
