import styled, { css } from 'styled-components';

interface ContainerProps {
  isShow: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  width: 15em;
  top: 0;
  bottom: 0;
  left: 0;

  background-color: var(--cor-fundo);
  -webkit-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);

  transition: all ease 0.5s;

  ${props => props.isShow && css`
    transform: translateX(0);
  `}

  ${props => !props.isShow && css`
    transform: translateX(-20em);
  `}

  z-index: 60;
`;

export const TopWrapper = styled.ul`
  width: 100%;
  height: 4.08em;

  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--cor-fonte);

  background-color: var(--cor-fundo);
  -webkit-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
`;

export const MenuWrapper = styled.div`
  list-style-type: none;
  margin: 1em;
  padding: 0;

  li {
    margin-top: 1.3em;

    a {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--cor-fonte);
      text-decoration: none;

      display: flex;
      align-items: center;

      transition: color 0.3s;

      &:hover {
        color: var(--cor-fonte-hover);
      }

      &:active {
        color: var(--cor-fundo);
      }

      svg {
        margin-right: 8px;
        margin-top: -4px;
        font-size: 26px;
      }
    }
  }
`;
