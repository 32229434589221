import React, { useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';

import { Container } from './styles';

import { LeftSideBarContext } from '../index';
import { useAuth } from '../../../hooks/AuthContext';

import BurgerButton from '../BurgerButton';

const TopSection: React.FC = () => {
  const { signOut } = useAuth();
  const { setIsShowSideBar } = useContext(LeftSideBarContext);

  const logout = useCallback(() => {
    signOut();
  }, [signOut])

  return (
    <Container>
      <BurgerButton onClick={() => setIsShowSideBar(true)} />

      <p></p>

      {/* <FiSettings /> */}
      <Link to="/" onClick={logout}>
        <FiPower />
      </Link>
    </Container>
  );
}

export default TopSection;
