import React from 'react';

import { Container } from './styles';

interface BurgerProps {
  onClick(): void;
}

const BurgerButton: React.FC<BurgerProps> = ({ onClick }) => {
  return (
    <Container role="button" onClick={onClick} >
      <i></i>
      <i></i>
      <i></i>
    </Container>
  );
}

export default BurgerButton;
