import React from 'react';
import { Switch, useLocation  } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Home from '../pages/Home';
import LeftSideBar from '../animations/LeftSideBar';

const Routes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <LeftSideBar />}
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/home" component={Home} isPrivate />
      </Switch>
    </>
  )
}

export default Routes;
