import React, { useState } from 'react';

import { Container, ContainerOverlay } from './styles';

import TopSection from './TopSection';
import LeftSection from './LeftSection';

export interface ILeftSideBarContext {
  isShowSideBar: boolean;
  setIsShowSideBar(value: boolean) : void;
}

export const LeftSideBarContext = React.createContext<ILeftSideBarContext>({} as ILeftSideBarContext);

const LeftSideBar: React.FC = () => {
  const [isShowSideBar, setIsShowSideBar] = useState(false);

  return (
    <LeftSideBarContext.Provider
      value={{ isShowSideBar, setIsShowSideBar }}
    >
      <Container>
        <ContainerOverlay
          isShow={isShowSideBar}
          role="button"
          onClick={() => setIsShowSideBar(false)}
        />

        <TopSection />
        <LeftSection />
      </Container>
    </LeftSideBarContext.Provider>
  );
}

export default LeftSideBar;
